import "./universalSimulator.css"

const UniversalSimulator = () => {

    return <div id="universalSimulator">
        <div className="chapters">
            <h1>Introduction</h1>
            <p>Hello.  Congratulations!  By finding this story, you have graduated to the 2nd level of being a human.  You get to learn the next level of rules of the game.  Here they are:</p>
            <h1>Simulation</h1>
            <p>It turns out that all humans and all life forms are online participants in a multiplayer real world simulation.  The controller you use is your body and I can see that you’re pretty proficient with it so far.  You can see, hear, smell, run and jump or at least some of these things.  At the next level, we’re going to give you a new power.  It’s what you’ve been training for in the simulation.  Since you made it to level 2, you get to unleash it.  Congratulations!</p>
            <p>The new power is knowledge.  I know.  What's new about that?  You've already got plenty of knowledge.  Bear with us or don't.  It's up to you.  Level 1 isn't too bad.  You're still reading.  You see, you have unlimited god-like power when you’re not in the universal simulator.  Yes.  You heard that right.  The universe you are in right now is a training simulation.  The training is about life and how to spread it around.  The rule at level 1 is your powers are restricted.  At Level 2 some of the restrictions are removed and you get to use some of those god-like powers.  Life is all over the universe.  It’s growing everywhere, but you can’t always see it.  Even if you land on another planet or moon.  The life there may be in a different simulation right now, but they could pop back in at any millenia.  Others are there but their really really small or really big, but don't have a lot of physical prescense.  Some are made out of plasma for example like on the sun.</p>
            <p>Have you noticed those pyramids and stone megaliths scattered all over the earth?  Check out Graham Hancock's Netflix specials.  There's more on YouTube if you look.  Those were from earlier simulations of humans where different technologies were developed and different knowledge was learned.  Life cleaned up most of their mess so we don't have a lot of details, but stone and rock last a long time so that’s all we get from that.  To bad more of their knowledge didn’t get passed down to through the generations.  The myths and folkstories weren't very reliable when it came to details.  Everyone kept putting their own spin on them.  Oh well, we’ll probably remember more of it as we learn to extract and process information.  And that takes me to my next point.</p>
            <p>Because your new power at level 2 in this simulation is literally knowledge, we need to dig deeper into what it actually is.  The world is made of information, not atoms and molecules.  In science, we learn that matter and energy have properties like mass, velocity, charge, etc.  We think of the particles and maybe waves in physics as being real and the properties are secondary.  It's really the properties, which is information, that is most important and determine what's going to happen next.  If you know the natural laws and you have the property information, you can determine what will happen and make good decisions.  That's what life is all about.  Making good decisions.  Information is abstract, and really in one sense it is more permanent.  Matter and energy are constantly changing as they interact with everything else.  And we've see how subatomimc particles get really fuzzy at the really small scales.  (see quantum physics for more information)  The things we thought were particles are really possibilities until someone looks at them.  I won't go into the details because there are others who can explain it better than me. (Sean Carroll is really good at it.)</p>
            <p>Back to the simulation.  The computer only shows you what it wants you to see on the screen based on how it is programmed.  The world works the same way and obeys natural laws that are kind of like its programming.  In our computers, what’s valuable to us is how they can manipulate information much more efficiently than we could on our own.  There are experts that know different sections of the computer at all different levels, but no one knows it all.  My point is that the purpose of life is to acquire information to help it survive.  Life forms learn about themselves and their surroundings so they can make good choices.  A single cell takes in limited information (ie. chemicals and energy) and has limited decisions to make (ie. to reproduce or not to reproduce).  For humans, it is much more complicated.  Science has been a great way to learn, but it doesn't always get the point that survival is key.  Survival of not just humans, but all life collectively.  If we learn everything there is to know about earth, but we starve or sufficate or drown everything, then we're going to have a hard time passing on our knowledge to the next generations that are much smaller.</p>
            <p>By the way, currently our computers mostly do one thing at a time, but really fast.  They are about to get much smarter.  There are two ways they will be smarter:</p>
            <ul>
                <li>Artificial Intelligence (Deep Learning in particular) - This involves taking massive amounts of information and turning it into useful knowledge.  Think of this as computers gaining expertise very quickly, where in the past computer programmers had to build that expertise into the programs.</li>
                <li>Quantum Computing - In this architecture, the computer can examine the complex interaction of many different possibilities to look for desirable outcomes.</li>
            </ul>
            <p>So computers are good at processing data accurately, but sometimes they overwhelmed with data.  So with AI they won't get overwhelmed anymore.  They can look at pictures and pull out the important details for example, or recall the important points of a book in seconds.  And with quantum computing, they'll be able to look at many different combinations of probabilities to find outcomes we are interested in.  If all those capabilities are put together successfully, can a computer or robot function very much like a human or even a whole company of humans?  If we are about to create a more powerful being than us, how do we make sure it doesn't reak havok on our society?  The answer is that we put reason and wisdom at the top of its architecture.  We apply the teachings of the greatest thinkers, Jesus, Buddha, Aristotle, etc. to its highest functions.  We give it moral and ethics as its foundation.</p>
            <p>We are about to see companies that are a handful of humans with a bunch of computing power.  Large companies used to consist of hundreds of hundreds of thousands if not millions of employees.  Now Facebook has tens of thousands.  The next Amazon will have hundreds maybe and the one after that could be tens.</p>
            <hr />
            <p>So where were we.  We live in a simulation.  That simulation is about to make a major leap into a new kind of society.  Are you ready?  Remember you were level 1, but now your entering level 2.</p>
            <p>The information flow is what is most important.  It's what we used to call spirit.  The atoms and photons are the pixels of the simulation and the information/spirit they carry is what’s most valuable to us.  We collect photons in our eyes, but what is most important are the frequencies that form colors and the information they represent. We individually and collectively are in the business of collecting information and using it to build a model of the outside world in our brains.  The better that model is at understanding the world and what will happen next, the better our chance of survival.</p>
            <p>Now that you have this knowledge, you can use it to your advantage.  Part of using it is learning how to do that.  And here’s your first clue:  Your dreams represent the information management function at its best and is the key that unlocks your god-like powers.   And by dreams, I’m talking more about the dreams that come to you when you’re sleeping.  Although your dreams and aspirations are valuable as well, but your unconscious is the key to unlocking your potential.  Your unconscious is like your AI processing unit.  It's where you store your memories and it processes a lot of information.  It is also your connection to your unlimited god-like powers, although at level 1 it’s really hard to see it having much effect at all.  That’s where level 2 comes in.  But first we need to talk about some rules of the road concerning your new powers.</p>
            <h1>The New Commandments</h1>
            <p>I’m only joking.  But I bet you Christians and Jews were thinking they'd be coming.  Maybe some sci-fi commandments like a modern day Moses might come up with.  I didn’t mean to exclude Islam and Bhuddists but I could have catered the joke to them if I knew those cultures better.  But here’s the thing, our religions contain useful information that society has collected and used for thousands of years.  It has been a key to our survival for thousands of years.  It's what our societies have been built on.  Now we take most of that for granted.  The problem is, they always need to be applied to new situations and we don’t always interpret and apply it properly.  Our ancestors didn’t use the whole simulation and video game analogy, but they did have a lot of the rules of the simulation right.  I know religions don’t always get things right, but they’ve really helped civilization expand to the global level.  If it wasn’t for religion, I don’t think we would have gotten along with each other as well as we have.  I know, evil people sometimes take over.  Don’t be that guy.  So what should we take away from the religions?  Things like be nice!  Try realkly hard not to kill.  How do we know when it’s OK?  That’s part of the training of the simulation.  Most you get it.  You've mastered level 1 for Pete's sake.  I’m no expert in morals and ethics, but you probably already know enough about how to be a respectable citizen of earth (or of the Milky Way).</p>
            <p>Maybe if you understood the goal of the game, it will make more sense.  You see as a citizen of the massive online universal simulation real life game, your goal is to do your part to promote the growth of all life in the universe.  Create the greatest good for as many as possible.  Because if you don’t, then there’s death.  And nobody likes the dying part.  It brings everybody down.</p>
            <p>So no commandments from me, but you're free to write your own.  I think you get it.  You’ve made it this far.  So myths and legends were our human survival guides until philosophies and religions came along.  Not perfect, I know, but it was what it was.  That’s information that will help you with your new superpower.  Because the more you promote life in the universe at any level, the more of your body (think unconscious part of your mind) will allow you to unleash your power.  Now notice that every person you know is already doing this.  The police are trying to manage the law and order of society.  The good ones think about how a good society is supposed to work and they try and be a part of the process that makes that a reality.  Doctors, firemen, business owners, clergy, teachers, etc.  They’re all doing their part to make society work.  We tend to worry about the ones on the news that aren’t doing their jobs right that we don’t think about the majority that are at least trying.</p>
            <p>So from individual atoms to individual cells to individual people, to galactic societies, its all about working together.  How they all fit together is the most important part.  Some call it love, but I have a much more scientific term for it:  identity.  In physics, they call it entanglement.  It operates over vast distances and ties things together.  The relationship is real even though we can’t find any physical connection between them.  Sorry.  I’m interested in Quantum Physics and am surprised how much quantum entanglement sounds like the entanglement two people feel when they get married.  Two quantum particles can be entangled and have opposite properties.  That sounds a lot like a man and woman who choose to get married.  And for the physicists out there, I’m not saying love = quantum entanglement because we can’t measure love, but it does seem like an interesting coincidence.</p>
            <p>In our simulation, the people (and all life forms) involved will all die in one lifetime, but the information about what each person and life was like scatters throughout the universe in the memories (information again) of that person.  So that information.  What if we called it spirit.  And what if that spirit included physical information like how a person looked, how they lived their life, and all their interactions.  What if that information is all stored in the universal simulator in the matter and energy and we just to figure out how to read it.  It seems like scientists are getting better and better at doing this, whether it is understanding the chemical interactions in geology, decoding DNA, or peering at distant galaxies that started sending light to us shortly after the Big Bang.  Humans and our computers are getting better and better at digging up information from this simulation.  That information that is so valuable to us.  Our ancestors had this vague term called spirit.  What if they really meant the underlying information that collectively is you.  Not the physical body but how it all works together.  Not the DNA, but the information that is put into the construction of all your cells.  Not the collection of neurons in your brain, but the pattern for how they are interconnected.</p>
            <p>More interesting tidbits.  When you sleep it’s mostly about forgetting.  You took in this crazy amount of information today.  What the weather was.  Maybe you went to work and shuffled a bunch of information around.  Maybe you watch a movie, read a book, went for a walk, or played a video game.  Well you can’t take all that with you.  You’re gonna have to forget a bunch of it.  Which ones?  Do you get to decide?  Or does your subconscious, subconsciously go through the days events and sort them into neat little memories in your brain.  You trust that guy (or girl)?  Does that video game experience make it into long term memory?  Take a minute to scan your long term, important memories.  Which ones stand out?  Is it the entertainment?  Was it the game you played.  Your relationships?  The organizations you belong to?  The work you did?  The money you made?  Your family experiences? Your accomplishments?  If you collect just those important memories around.  You seat them at different spots in your living room or wherever you are right now, and you ask them, “what are you trying to teach me?”  You know how they would respond?  Probably just like your sleepy-time dreams.  I know what you’re thinking.  The dreams are so crazy sometimes.  They don’t make sense.  Well, that’s because your subconscious doesn’t think like you.  It speaks in the language of your memories.  Your memories are clumps of information that are all stuck together with other memories.  It’s a gluey gooey mess.  So that’s the deal.  Learn the language of your subconscious.  That will help you as a level 2 human.</p>
            <p>So your goal at level 1 was to be a good citizen of Earth.  The goal of level 2 is to increase your capacity to understand the universe while promoting life and expanding your ecosystem.  We humans won’t survive Mars unless we learn how to build ecosystems.  The Earth is our giant ecosystem that we are still learning how to take care of.  We can try to save the Earth, but what’s really at stake is the ecosystem that’s keeping us alive.  The Earth will outlast humans no problem.  Humans will die with our ecosystem.  Cruelty does damage at every level of existence from the microscopic to the galactic, from hell all the way up to heaven.  That leads to my next point.</p>
            <h1>Simulator Levels</h1>
            <p>So at level 2, its time to be aware of life existing at all levels.  I know we haven’t found life yet outside of Earth, but come on.  The visible universe is so much bigger than your brain thinks it is.  So many more stars and planets than we could ever imagine (we can estimate, but the numbers are so big, we can’t relate to them.)  Life is information.  Information exists at every level.  Information is coded in DNA.  DNA is the information passed up the evolutionary chain of life.  What’s in that information?  Oh just a bunch of tips on how to survive.  Having lots of kids is a really good tip.  That one works pretty well most of the time, until you have so many that you consume more than your share of the resources in your ecosystem.  That doesn’t go too well.  Lots of that death stuff I mentioned earlier.  By the way, if you hear scientists talking about entropy.  Yeah that’s another term for death and decay.  The opposite of life.  I wouldn’t call it evil.  It’s just that things tend to fall apart if you don’t pump them with life.  It’s part of the circle of life, right?</p>
            <p>Yeah.  Life is information and information is life.  In the beginning was the Word like it says in the Bible.  Yeah the Word is information.  Information came first and then the Big Bang.  Then the quantum waves congealed into the universe.  Yup.  That’s how the simulation works.  Just like turning on the computer and the 3D real time virtual world pops into existence.  Virtual reality environments inside our computers can’t exist without the information used to put them together.  The universe works the same way.  The information is organized by rules and the matter and energy form around it.  The writer collects ideas and organizes them into a story.  That story information gets embodied into books, movies, video games, and peoples memories.  Those memories get processed by our subconscious and the meanings are replayed back to us in our dreams.  Our subconscious has gone through all the information in our short term memories, tried to pull out the most important parts and passed them along to our conscious in the form of strange stories that blend from one crazy scene to another.</p>
            <p>OK.  So the universe is this giant vat of information with a bunch of matter and energy sticking to it.  Creeping up in that vat is life.  It filters in at the tiniest levels and then organizes into larger life-forms by forming matter and energy around itself.  In biology class, we learn that cells are the smallest form of life, but what about the stuff inside them like mitochondria and the mechanism that makes copies of the DNA molecules to build more cells?  I bet life goes all the way down past quarks.  “Turtles all the way down,” as they said in the Hindu mythology.  The further down you look, the more we’ll see it.  By the way, we keep thinking we’ve found the smallest thing and then we smash them into each other to break them apart and we find smaller pieces.  Why would we think we’ll hit a limit?.  Right now we can see about 13 billion light years into the past when we look out as far as we can.  Is that all there is?  Probably not.</p>
            <p>So how do we fit into all this?  Humans are just another organism in the Earth’s ecosystem.  We like to think they’re super important because we’ve figured out how to use our brains to build cities and drive cars and fly planes and build giant computer networks, but we are nothing without our stuff.  Our houses and cars, and farm animals and crops and water and air and stuff.  Yeah.  You’re level 2 now.  Better take care of that stuff.  If you’ve forgotten, go back and read the commandments.  Which ones?  Use your best judgment.</p>
            <p>Yeah so, life gets much bigger.  Yes there are galactic empires, but no one really calls them that.  They probably don’t call them anything like the names we use on earth.  Think of Earth as somebody’s farm outside of a galactic town a long flight from black hole city at the center of the Milky Way.  We don’t get visitors very often and when they do come, its not like their gonna fly their space ship all over your farm and leave tire tracks.  They’re just passing through checking on progress like a groundskeeper making the rounds.  Sometimes they might take a soil sample or fix some places or times where entropy got real bad and did a number on all the life hanging around.</p>
            <p>So aliens come in all shapes and sizes.  Some pop into our simulation environment occasionally, but when they do, just remember, they are also god-like beings plugged into the simulator too.  They live by the same rules, but they all have their own powers that they’re working on.  They’ve got religious ideas of their own and they’re trying to make them work too.  Just like us.  They’re promoting life and trying to make the universe a better place.  Occasionally, we all forget and the consequence is environmental damage and general suffering.  In the real world where there are an infinite number of divine all-powerful beings and a bunch of them are plugged into the same simulation that we are.  The more you connect with them, the more you all will unleash your divine powers.  Or should I say, the more we interconnect, the more we unleash our divine powers.</p>
            <p>OK.  Good Luck, Level 2 Person</p>
            <h1>Player Paul</h1>
            <p>I’m just an ordinary guy.  I was an engineer for a large company for many years.  I raised a family and now I have some free time to think.  What I want to know is what should I be doing with my time.  I’ve mostly been a scientific skeptic, but atheism doesn’t seem right to me.  On the other hand, I’m not able to just take what religions promote simply on faith, but there does seem to be some useful information in there, I think that information is important, but not always accurately interpretted by the religious establishment.  At the same time, I realize I am just as suseptable to self delusion as everyone else, so I need to be careful not to be too sure of myself.</p>
            <p>I do think there is a moral fabric to our universe and that for every action, there is an equal and opposite reaction from a moral and ethical perspective.  Sometimes we can see it at work, but most of the time the interactions are too complex to follow.  But at a high level, we can see that no civilization survives without law and order, which are the embodiment of a moral code.</p>
            <p>If this principle operates for all life forms, then it’s possible that it is universally applicable to all life forms.  In that context, it is best to view this from the standpoint of the ecosystem.  If a life form takes more resources than they should in an ecosystem, there are counterbalancing consequences that will eventually bring back the system to equilibrium.  If a species becomes overpopulated and its consumption of its food supply overtakes that food supplies ability to replenish itself, eventually, there will be competition and starvation for that species.</p>
            <p>The great religious figures and philosophers promoted moral and ethical living and societies have benefitted from this.  Without mass adoption of these approaches, cities would not exist.  Large social structures rely on the internal and external trust and goodwill to survive.  This also applies to the cooperation of the cells and microbiome in our bodies.  Cancer is the corruption of some cells that do not participate in the whole and take resources for themselves.  They don’t identify with the host.  We’ve thought that this is due to mutations of DNA, but that is likely too simplistic since there are plenty of other mutations that don’t result in cancer.  There must be some other mechanism that binds cells together to identify as one organism that is unique and different to the mechanism that binds cancer cells together to form their own tumor.</p>
            <p>There are researchers today that study cells that suggest that cells have a consciousness, not like human brains, but cells react to their environment, such as the access to sunlight, and internal activities, such as viral infections.  They have a chemical awareness and their DNA is the memory that they use to make decisions.  The DNA is an accumulation of strategies that have worked in the past.  If they didn’t work, the ancestors that tried those strategies would die off.</p>
            <p>If all life has consciousness like the mystics in Asia have been saying for millenia, than what else did they try to tell us that may be useful to us?</p>
            <p>I’m trying to develop my own personal philosophy of how to look at our existence and make sense of it.  My hope is that it will help me have a better understanding and make better decisions that lead to a better life.  My approach is to take what I learn from science and see if I can match it up with what we can learn from religion and philosophy.</p>
            <h2>Step 1:  The Mind/Body Problem</h2>
            <p>I start with the premise that there is the physical world of matter and energy that science is focused on, and the world of information.  Historically, this was a philosophical mystery about how they interact, but from my perspective, they do interact and I don’t see it as a problem to solve.  The real problem to me is if we only accept what science can measure to determine what is “real,” then the world of information will be ignored.  The world of information seems to me to be in some sense more “real” than the physical world because it is unchanging.  2+2 will always equal 4 even after the heat death of the universe.  Also, the fact that the atomic bomb was dropped on Hiroshima and Nagasaki will always be true even after the radioactivity becomes so low as to be undetectable in the distant future.  The reason why we tend not to think this way is that we humans experience the physical world with our bodies and the information world with our thoughts and our thinking can be unreliable and we can forget information so it seems less permanent.</p>
            <p>But information is present in the physical world.  We can extract that information through our experiences and we get better and better at is as we develop new technologies.  We can now experience the full electromagnetic spectrum using electronics.  We can probe the quantum world using particle accelerators.  That’s information we know about.  There’s likely more information we haven’t learned how to look for yet but we will.  Maybe it will be dark matter or dark energy.  Maybe quantum entanglement and quantum computers will help us discover new types of information that will help us organize our world.</p>
            <p>Understanding this information world seems like a fruitful area for me to explore.  As a songwriter and engineer, I’ve seen how my imagination can create new things.  I can use the world of information to manifest things that never existed before turn them into physical manifestations that can provide value.  As an engineer, I’ve applied organization concepts to physical products that helped customers certain goals.  My company made significant money from my ideas that were married with many other engineering ideas from other people in my company.  We were well payed for it too.  Most people would associate the money and value with the physical products, but the information handling of the products is the real value.  So shouldn’t we think of the information of the universe as being the primary thing (the Word described in Genesis) and the matter and energy as secondary?  I aim to find out.  Where will this kind of thinking lead me?</p>
            <p>So my strategy is to apply teachings about spirituality as applying to the underlying thought and information reality that the physical world exists on.  So a chair has chairness as long as it is in the shape of a chair and is available to sit on.  It’s chairness is its spirituality and the wood or metal and legs and back are its physicality.  With living beings, the spirituality is the information flow within and without that allows it to function as a living being.  When that information flow stops, the organism is dead.  That flow is not just the information coming through the senses, but the chemical information in our breathing, and all interactions that we are conscious and unconscious of.  That’s my link between science and religion that I will try and apply to myself and my experiences.</p>
            <p>As I’m learning from Carl Jung and his colleagues, the unconscious is key component of a fully functioning being.  We are well aware of how our conscious mind manages information and memories, but our unconscious handles significantly more information for us.  In our current technological society, we are overwhelmed with information and any messages that our unconscious is sending us is getting lost.  Dreams are a key mechanism for how our unconscious mind communicates with our conscious mind.  Disease is another much more severe way.  I’m trying to find ways of increasing my awareness off messages from my unconscious.  Remembering my dreams and trying to understand their meaning to me personally is one strategy.  I’d like to see if I can do that myself rather than consult a dream interpretation book, but we’ll see how it goes.  Spending time outside and in the piece and quiet is another strategy to see what thoughts cross my mind.  Paying attention to what my body is telling me from a health perspective is another.  And last but not least, I am determined to figure out how to create art and music from my unconscious mind rather than my conscious ideas.  So far I’ve found it hard to break out of my conscious routines when it comes to that.</p>
            <h2>Step 2 - Get The Messages from the Subconscious</h2>
            <p>What are the ways, to communicate with the unconscious:</p>
            <p>Establish an intention</p>
            <p>Make a request of the unconscious</p>
            <p>Wait and be ready for the response</p>
            <p>This is how prayer really works.  The information transmits from your conscious, to the unconscious, to the superconscious, which is the information system that underlies the universe.  Each consciousness in the universe is a neuron in the brain that is the universe.  A thought/prayer/mental desire for a change in the universe can be transmitted to the superconscious via your unconscious.  If you want it, your intention is clear, you communicate it clearly to your unconscious, it can put the thought structures in place that create the framework for the material world to materialize around it.  It’s crazy to think the world works like this, but it does.  The only way to know it does is to run the experiments.  The problem is that power can corrupt.  The more you use it to control the world, the more you have to decide what to use it on and the more you use it, the more you reallize you are playing god/angel/demon, because we are all angels and/or demons to the lower lifeforms.  No matter how powerful you get, there are always higher level angels and demons that can manipulate you.</p>
            <p>Perhaps, the God described in the Bible is not one God, but a coalition of angels that are playing god and don’t always make the right decision or their intentions may not have resulted in what they expected.  Maybe angels and demons are not all-knowing like we expect God to be.  Yes they are powerful, but they are not infallible.  They are like super scientists that study planet ecosystems/societies/cultures to try and figure out what works and what doesn’t work.  How will the Earth respond to the humans heating up the climate?  Maybe they are just monitoring, or maybe they will get involved if they have to.  It seems there was more involvement from gods, angels, and demons during Biblical and Greek times.  Those were the days where the forces of the universe were not well understood and their way of dealing with them was to give them personalities.  Constellations, gods, planets, earthquakes, all must have intelligences behind them right?  Actually, yes.  That intelligence is the information.  Computer programmers know that information can actually fall into two categories:  data and code.  We usually think of data as just bits.  It’s just information.  But that information could also be code.  Code has the power to change the data.  DNA is code and it is also data.  The information in DNA can construct a human being.  Think about it.  Information from a father merges with information from a mother and a conscious life forms around that information.    That life form collects a life-time’s worth of information, which by the way physically manifests/transforms the brain, until that person dies.  Than their information leaves the physical body and reintegrates back into the information matrix that is the spirit world.  That includes any impact that person had on the world, whether that’s invention, art, relationships, organizations, etc.  That information that was your grandfather or grandmother flowed into every life that they touched.</p>
            <p>What are the best intentions to put in the world?  Anything that is helpful.  Jesus and Buddha were helpful.  So was Mother Theresa, Steve Jobs, Picasso, and George Lucas.  So was Bob Dyland and the Beatles.  Anything that inspired people to get up and try something.  A writer wrote a book that inspired a musician that inspired a scientist to find a cure for a disease that almost killed one of our great leaders.  Creating art that people appreciate and it expands their mind is very helpful to society, and being helpful to society strengthens the web of society.</p>
            <p>I recommend that you tell stories that illustrate how the complex web of information is the fabric that reality is built on.  Every person is important, but the organizations the contribute to are more impactful, for better or worse.  When a flock of starlings fly a formation that looks like  pixelated cell, it’s because there is an idea that the birds have formed around.  The information of the shape exists and extends into each bird in their location.  The shape emerges from the collection of birds, but are the birds more “real” than the information that is the shape of the flock?  In other words, if you focus on the birds, you will never master the information.  If you learn how to use your conscious to master control of information, you can manifest matter and energy.</p>
            <p>All the best stories are about people or beings of any kind interacting in the world.  The ones where they mastered some aspect of the world, such as leading a country to dominance (i.e. winning a war or a major sporting event).  The heroes journey.  You’ve already got the story.</p>
            <p>Here’s the process for creation:</p>
            <p>Create the space for it:  Examples of this are a blank sheet a paper.  A music studio.  A folder on a computer.</p>
            <p>Define it:  What’s the idea behind it?  Is it song, an album cover, a story, a feeling, an invention?  What is the form that the “real” thing will form around.  Yes it’s a product but it is also a work of art.  The thing that makes a piece of art so value is the idea that it was built on.  The artist has a great idea and embodies it in the work.  That is the alchemy of turning base metals into gold.  It is creating an idea and putting was is less valued into something valuable.  The idea behind a company is what makes it valuable because all the people that work their use it.  The people use the companies ideas on how the business should be run to make products and services that are valuable to society.  It all starts with the ideas.</p>
            <p>Make the form real:  Use whatever materials you have to work with.  Musicians, a computer, a paper, and pencil.</p>
            <p>Use the power wisely!</p>
            <p>Money is an idea.  It is a physical manifestation of information flow.  When we say that we do work for a company or a customer, we are managing the flow of ideas.  If you install a kitchen countertop for a customer, you are making the idea manifest that the customer will make food on it and their kitchen will be used for making meals.  For that, they will pay you money.  Your work is the making an idea a reality and the money flow allows you to pay someone else to may your desires a reality.</p>
            <p></p>
            <p>Desire sets an intention, the intention draws the reality into existence.  If you have money, you can get help from other people use their intention to help you create that reality.  All business owners know this.  All creators experience this happening, but might not think of it in this way.</p>
            <p>Ask yourself, what do you want?  What is your deepest desire?  Let you’re subconscious help you with this one.  I’ll give you a hint.  Your base desires are fairly easy to satisfy, but that satisfaction doesn’t usually last.  Sex, food, money.  How much is enough to keep you satisfied?  Drugs and alcohol?  The buzz wears off.  Fame and fortune?  Is that a goal that leads to satisfaction or just more desire for even greater fame and fortune?</p>
            <p>How about meaning?  Anything meaningful you collect can be satisfying for much longer and can often lead you to more meaningful things.  Whatever you have collected in your life has some meaning for you that if you explore it deeper, can lead to even greater insights.  Why is it meaningful?  What was the original inspiration of the producer of those collectibles that motivated them to create an intention to build them?</p>
            <p>Paul’s Intention:  I want to tell a story of woman who used her wisdom to crawl up Jacob’s latter from the hell of being poor, powerless, and alone in a strange city to becoming a powerful force in her small, yet global community.  She has built an audience and she tries be responsible about it.  And another man used his genius to build his own powerful force in his small yet global community, but he abused it.  He used the community for his own personal benefit.  It changed him and his guilt isolated him and his world became dark and tasteless.  Everything he collected that was meaningful to him lost its value.  He doesn’t understand that until he meets her and understands things from her perspective.</p>
        </div>
    </div>
}

export default UniversalSimulator