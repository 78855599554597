import React, { useRef, useEffect } from 'react';
import MidiFileCreator from './MidiGen';

function MelodyCanvas({ instance, sequence, index, ticksPerEvent }) {
    const canvasRef = useRef(document.getElementById(instance + '-' + index));
    const canvasHeight = 128;
    const canvasWidth = 300;
    const xWidth = 300/sequence.length;
    const yHeight = canvasHeight/128;

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        
        for (let x = 0; x < sequence.length; x++) {
            if (sequence[x] === 'H') {
                context.fillRect(x * xWidth, canvasHeight - sequence[x - 1], xWidth, yHeight);
            } else if (sequence[x] !== 'R') {
                context.fillRect(x * xWidth, canvasHeight - sequence[x], xWidth, yHeight);
            };                        
        };
    }, [sequence, xWidth, yHeight]);

    MidiFileCreator(sequence, ticksPerEvent, instance, index);

    return (
        <div key={instance + "-" + index}>
            <canvas id={"canvas-" + instance + "-" + index} className="melodyCanvas" ref={canvasRef} width={canvasWidth} height={canvasHeight}/>
        </div>
    );
};

export default MelodyCanvas