import { useState, useRef } from "react"

function SimplePlayer( { tracks } ) {
    const [selectedSong, setSelectedSong] = useState(0);
    const audioRef = useRef();

    return (
        <>
            <h2>{tracks[selectedSong]['title']}</h2>            
            <audio 
                controls='true'
                ref={ audioRef } 
                src={ tracks[selectedSong]['file'] } 
                // onLoadedData={handleLoadedData} 
                // onLoadedMetadata={getDuration} 
                // onEnded={handleEnd} 
                autoPlay='false'
            />
            {/* {useEffect(() => <audio controls><source src={tracks[selectedSong]['file']} type="audiompeg" /></audio>, [selectedSong, tracks])} */}
            {/* <audio controls>
                <source src={tracks[selectedSong]['file']} type="audio/mpeg" />
                Your browser does not support the audio tag.
            </audio> */}
            {tracks.map((track, index) => <div onClick={() => setSelectedSong(index)}>{track['title']}</div> )}
        </>
    )
}

export default SimplePlayer