import { useState } from 'react';
import './Production.css';
import SimplePlayer from './simplePlayer';
import lists from './production.json';

function Production(colorModeClass) {
    const artists  = lists["artists"];
    const artistComments = lists["artistComments"];
    const artistSongs = lists["artistSongs"];
    const [ artistSelection, setArtistSelection ] = useState('none');

    const handleClick = (selection) => {
        setArtistSelection(selection)
    }

    return (
        <div className={colorModeClass}>
            
            <div id="overview">
                <div>
                <img id="logo" src="./production/images/QuietmanProdLogo.png" alt="" />
                </div>
                <div id="overviewText">
                <p>Here are some of the projects I've worked on through the years.</p>
                </div>
            </div>

            <div className="sections">
                <div className="bands">
                    <p><b><a href="http://www.dangblastedcranks.com/" target="_blank" rel="noreferrer">Dang Blasted Cranks</a></b> - My original rock band can be found on YouTube, Spotify, and most audio streaming services.  Much of the material was recorded and mixed in my studio.</p>
                    <p><b><a href="http://www.3ifbyair.net/" target="_blank" rel="noreferrer">3 If By Air</a></b> - My rock cover band that has played clubs throughout the Chicago Northwest suburbs.</p>
                    {artists.map(
                        (artist, index) => 
                        <div key={index}>
                            <p>
                                <button className={ artistSelection === artists[index] ? "selectedArtist" : "unselectedArtist"} onClick={() => handleClick(artists[index])}>
                                    {artist}
                                </button>
                                {artistComments[index]}
                            </p>
                            {(artistSelection === artists[index]) && <div><SimplePlayer tracks = {artistSongs[index]}/></div>}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Production