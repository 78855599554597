// import { useMemo } from "react";
import GenerateScale from "./musicalFunctions";

const GenerateSequences = ((
        noteSizes,
        keys,
        scales,
        userSequences,
        userMeasures,
        userBeatsPerMeasure,
        userBeatSize,
        userNoteSize,
        userRestsToStart,
        userNotesInPhrase,
        userKey,
        userScale,
        userLowestMIDINote,
        userHighestMIDINote,
        userVariance,
        userBusyness,
        userLegato
    ) => {
    const tonicIntervals = GenerateScale( scales, userScale );

    //
    //          Define the scale in the first (lowest octave)
    //
    // Find the position of the key in the list of notes array so all notes can be derived from them.
    let firstOctave = [];
    let notesInScale = [];
    firstOctave.push(keys.findIndex((item) => item === userKey));
    
    // Create an array of notes in the scale for the first octave from which all others can be derived.
    tonicIntervals.forEach((item, index) => {
        let nextNote = firstOctave[index] + item;
        if (nextNote < 12) {
            firstOctave.push(nextNote)
        } else {
            firstOctave.push(nextNote - 12)
        };
    });
    firstOctave.sort(function(a, b){return a - b});
    for (let i=0; i < 128; i++) {
        if ((i >= userLowestMIDINote) && (i <= userHighestMIDINote)) {
            if (firstOctave.includes(i % 12)) {
                notesInScale.push(i)
            }
        };
    };

    //
    //         Calculate the number of events in each sequence
    //
    const eventCalculator =(( measures, beats, beatSizeIndex, noteSizeIndex ) => {
        return (measures * beats * 2 ** (noteSizeIndex - beatSizeIndex));
    })

    let totalEvents = eventCalculator( 
        userBeatsPerMeasure, 
        userMeasures, 
        noteSizes.findIndex((item) => item === userBeatSize),
        noteSizes.findIndex((item) => item === userNoteSize)
    );

    //
    //       Create a cadence that will be used in the generation of all the sequences.
    //
    let sequenceCadence = cadenceMaker(
        userRestsToStart, 
        userNotesInPhrase, 
        totalEvents, 
        userBusyness, 
        userLegato
    );

    function cadenceMaker(restsStart, events, totalEvents, probOfNote, probOfHold) {
        //  function to create a rhythmic cadence for the melodies  
        let cadence = [];
        const restsEnd = totalEvents - events;
        for (let i = 0; i < restsStart; i++) {
            cadence.push('R');
        };
        let noteInPlay = false;
        for (let i = 0; i < events - restsStart; i++){
            if (Math.random()*100 < probOfNote) {
                if (noteInPlay) {
                    if (Math.random()*100 < probOfHold) {
                        cadence.push('H');
                    } else {
                        cadence.push('N');
                    };                                    
                } else {
                    cadence.push('N');
                    noteInPlay = true
                };
            } else {
                cadence.push('R');
                noteInPlay = false
            };
        };
        for (let i = 0; i < restsEnd; i++) {
            cadence.push('R')
        }; 
        return cadence;              
    };    
    
    //
    //        Generate melodies based on the cadence and the notes from the scale.
    //
    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is exclusive and the minimum is inclusive
    }; 
    
    //
    //        Normal distribution is used to determine intervals.
    //
    function getRandomNormal(min, max) {
        // Generate two random uniform numbers between 0 and 1
        const u1 = Math.random();
        const u2 = Math.random();
    
        // Apply Box-Muller transform to get standard normal random numbers
        const z0 = Math.sqrt(-2 * Math.log(u1)) * Math.cos(2 * Math.PI * u2);
        // const z1 = Math.sqrt(-2 * Math.log(u1)) * Math.sin(2 * Math.PI * u2);
    
        // Scale and shift to fit the desired range
        const scaledValue = (z0 + 1) / 2; // Scaled to [0, 1]
        const randomInt = Math.floor(scaledValue * (max - min + 1)) + min;

        return randomInt
    };
                        
    function melodyMaker(modeIntervals, cadence, variance) {
    //        function to convert scale steps into MIDI note numbers acording to the cadence
        let currentNote = null;
        const firstNote = getRandomInt(0, modeIntervals.length - 1);
        let melody = [];
        for (let x = 0; x < cadence.length; x++) {
            if (cadence[x] === 'N') {
                if (currentNote === null) {
                    melody.push(modeIntervals[firstNote]);
                    currentNote = firstNote;
                } else {
                    currentNote = currentNote + Math.floor(getRandomNormal(-variance, variance ));
                    if (currentNote >= 0 && currentNote < modeIntervals.length) {
                        melody.push(modeIntervals[currentNote]);
                    } else if (currentNote < 0) {
                        currentNote = getRandomInt(0, variance);
                        melody.push(modeIntervals[currentNote]);
                    } else {
                        currentNote = modeIntervals.length + getRandomInt(-variance, 0);
                        melody.push(modeIntervals[currentNote]);
                    }
                }
            } else {
                melody.push(cadence[x]);
            };
        };
        return melody;
    };

    //
    //   Generate all the sequences requested.
    //
    let sequences = [];
    for (let i = 0; i < userSequences; i++) {
        let sequence = melodyMaker(notesInScale, sequenceCadence, userVariance);
        sequences.push(sequence);
    }

    return sequences;
});

export default GenerateSequences;