import { useEffect, useMemo } from 'react';
import MidiWriter from 'midi-writer-js';

function MidiFileCreator(sequence, ticksPerEvent, instance, index) {
        // Generate the MIDI data
    const midiData = useMemo(() => {
        // Start with a new track
        const track = new MidiWriter.Track();

        // Convert sequence to MIDI
        let currentEvent = { 'noteInPlay': false, 'noteStart':0, 'duration':0, 'note':0 };
        let newStart = 0;
        sequence.forEach((event, index) => {
            // console.log('currentEvent:  ', currentEvent);
            if (!currentEvent.noteInPlay) {
                if (event === 'R') {
                    currentEvent.noteStart++
                } else {
                    currentEvent.noteInPlay = true;
                    currentEvent.note = event;
                    currentEvent.duration = 1;
                }
            };
            if (index === (sequence.length - 1)) {
                if (currentEvent.noteInPlay) {
                    const finalNote = new MidiWriter.NoteEvent({pitch: currentEvent.note, tick: (currentEvent.noteStart * ticksPerEvent), duration: ('T' + currentEvent.duration * ticksPerEvent)});
                    track.addEvent(finalNote);
                }
            } else {
                if (currentEvent.noteInPlay) {
                    if (sequence[index + 1] === 'H') {
                        currentEvent.duration++;                    
                    } else {
                        const note = new MidiWriter.NoteEvent({pitch: currentEvent.note, tick: (currentEvent.noteStart * ticksPerEvent), duration: ('T' + currentEvent.duration * ticksPerEvent)});
                        track.addEvent(note);
                        newStart = currentEvent.noteStart + currentEvent.duration;
                        currentEvent = { 'noteInPlay': false, 'noteStart':newStart, 'duration':0, 'note':0 };
                    };
                };
            };
        });

        // Generate a data URI
        const write = new MidiWriter.Writer([track]);
        return write.dataUri();
    }, [sequence, ticksPerEvent]);

    // Create a download link when the MIDI data changes
    useEffect(() => {
        let link = document.createElement('a');
        link.href = midiData;
        link.download = `sequence-${instance}-${index}.mid`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, [midiData, instance, index]);  // Runs whenever midiData changes

    // Your component's render method here
    return (<p>Test</p>
        // <a>
        // {link}
        // </a>
    );
};

export default MidiFileCreator