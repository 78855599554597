const Slider = ({ label, userValue, setUserValue, min, max}) => {

    const handleChange = (event) => {
        setUserValue(event.target.value);
    };

    return (
        <p>{label + userValue}
            <input
                type="range"
                min={min}
                max={max}
                value={userValue}
                onChange={handleChange}
        />
        </p>
    );
};

export default Slider;