// Generates an array of intervals for one octave starting from the tonic.
const GenerateScale = ((scales, request) => {
    const modal = [ 2, 2, 1, 2, 2, 2, 1];
    const scaleIndex = scales.findIndex((item) => item === request);
    let intervals = [];
    if (scaleIndex < 7) {
        for (let i=0; i<6; i++) {
            if (scaleIndex + i < 7) {
                intervals.push(modal[scaleIndex + i])
            } else {
                intervals.push(modal[scaleIndex + i - 7])
            };
        };
    } else if (request === 'Minor Pentatonic') {
        intervals = [ 3, 2, 2, 3 ]
    } else if (request === 'Major Pentatonic') {
        intervals = [ 2, 2, 3, 2 ]
    } else if (request === 'Minor Blues') {
        intervals = [ 3, 2, 1, 1, 3 ]
    } else if (request === 'Major Blues') {
        intervals = [ 2, 1, 1, 3, 2 ]
    } else if (request === 'Diminished') {
        intervals = [ 3, 3, 3 ]
    } else if (request === 'Whole Tone') {
        intervals = [ 2, 2, 2, 2, 2 ]
    } else {
        intervals = [ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1 ]
    }
        
    return intervals;
});

export default GenerateScale