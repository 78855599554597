const Instruments = ({ setUserLowestMIDINote, setUserHighestMIDINote }) => {

  return (
    <div>
      <h2>Instrument Ranges</h2>
      <div className="instruments">
        <div className="instrumentGroup">
            <h3 className="instrumentGroupTitle">Strings</h3>
            <div className="instrumentGroupButtons">
              <button onClick={() => { setUserLowestMIDINote(55); setUserHighestMIDINote(103);}}>Violin</button>
              <button onClick={() => { setUserLowestMIDINote(48); setUserHighestMIDINote(89);}}>Viola</button>
              <button onClick={() => { setUserLowestMIDINote(36); setUserHighestMIDINote(84);}}>Cello</button>
              <button onClick={() => { setUserLowestMIDINote(28); setUserHighestMIDINote(67);}}>Double Bass</button>
              <button onClick={() => { setUserLowestMIDINote(24); setUserHighestMIDINote(103);}}>Harp</button>
              <button onClick={() => { setUserLowestMIDINote(28); setUserHighestMIDINote(67);}}>Four String Bass</button>
              <button onClick={() => { setUserLowestMIDINote(23); setUserHighestMIDINote(67);}}>Five String Bass</button>
              <button onClick={() => { setUserLowestMIDINote(40); setUserHighestMIDINote(88);}}>Guitar</button>
            </div>
        </div>
        <hr></hr>
        <div className="instrumentGroup">
          <h3 className="instrumentGroupTitle">Brass</h3>
          <div className="instrumentGroupButtons">
            <button onClick={() => { setUserLowestMIDINote(55); setUserHighestMIDINote(82);}}>Trumpet</button>
            <button onClick={() => { setUserLowestMIDINote(34); setUserHighestMIDINote(77);}}>French Horn</button>
            <button onClick={() => { setUserLowestMIDINote(52); setUserHighestMIDINote(81);}}>English Horn</button>
            <button onClick={() => { setUserLowestMIDINote(40); setUserHighestMIDINote(72);}}>Trombone</button>
            <button onClick={() => { setUserLowestMIDINote(34); setUserHighestMIDINote(67);}}>Bass Trombone</button>
            <button onClick={() => { setUserLowestMIDINote(28); setUserHighestMIDINote(58);}}>Tuba</button>
          </div>
        </div>
        <hr></hr>
        <div className="instrumentGroup">
          <h3 className="instrumentGroupTitle">Woodwinds</h3>
          <div className="instrumentGroupButtons">
            <button onClick={() => { setUserLowestMIDINote(74); setUserHighestMIDINote(102);}}>Piccolo</button>
            <button onClick={() => { setUserLowestMIDINote(60); setUserHighestMIDINote(96);}}>Flute</button>
            <button onClick={() => { setUserLowestMIDINote(58); setUserHighestMIDINote(91);}}>Oboe</button>
            <button onClick={() => { setUserLowestMIDINote(50); setUserHighestMIDINote(94);}}>Clarinet</button>
            <button onClick={() => { setUserLowestMIDINote(38); setUserHighestMIDINote(77);}}>Bass Clarinet</button>
            <button onClick={() => { setUserLowestMIDINote(34); setUserHighestMIDINote(75);}}>Bassoon</button>
            <button onClick={() => { setUserLowestMIDINote(56); setUserHighestMIDINote(88);}}>Soprano Sax</button>
            <button onClick={() => { setUserLowestMIDINote(49); setUserHighestMIDINote(81);}}>Alto Sax</button>
            <button onClick={() => { setUserLowestMIDINote(44); setUserHighestMIDINote(76);}}>Tenor Sax</button>
            <button onClick={() => { setUserLowestMIDINote(36); setUserHighestMIDINote(69);}}>BariSax</button>
          </div>
        </div>
        <hr></hr>
        <div className="instrumentGroup">
          <h3 className="instrumentGroupTitle">Percussion</h3>
          <div className="instrumentGroupButtons">
            <button onClick={() => { setUserLowestMIDINote(79); setUserHighestMIDINote(108);}}>Glockenspiel</button>
            <button onClick={() => { setUserLowestMIDINote(65); setUserHighestMIDINote(108);}}>Xylophone</button>
            <button onClick={() => { setUserLowestMIDINote(53); setUserHighestMIDINote(89);}}>Vibraphone</button>
            <button onClick={() => { setUserLowestMIDINote(45); setUserHighestMIDINote(96);}}>Marimba</button>
            <button onClick={() => { setUserLowestMIDINote(60); setUserHighestMIDINote(108);}}>Celeste</button>
            <button onClick={() => { setUserLowestMIDINote(60); setUserHighestMIDINote(77);}}>Tubular Bells</button>
            <button onClick={() => { setUserLowestMIDINote(40); setUserHighestMIDINote(55);}}>Timpani</button>
          </div>
        </div>
        <hr></hr>
        <div className="instrumentGroup">
          <h3 className="instrumentGroupTitle">Voices</h3>
          <div className="instrumentGroupButtons">
            <button onClick={() => { setUserLowestMIDINote(28); setUserHighestMIDINote(52);}}>Bass Voice</button>
            <button onClick={() => { setUserLowestMIDINote(33); setUserHighestMIDINote(57);}}>Baritone Voice</button>
            <button onClick={() => { setUserLowestMIDINote(36); setUserHighestMIDINote(60);}}>Tenor Voice</button>
            <button onClick={() => { setUserLowestMIDINote(41); setUserHighestMIDINote(65);}}>Contralto Voice</button>
            <button onClick={() => { setUserLowestMIDINote(45); setUserHighestMIDINote(69);}}>Mezzo-Soprano Voice</button>
            <button onClick={() => { setUserLowestMIDINote(48); setUserHighestMIDINote(72);}}>Soprano Voice</button>
          </div>
        </div>
        <hr></hr>
        <div className="instrumentGroup">
          <h3 className="instrumentGroupTitle">Keyboard</h3>
          <div className="instrumentGroupButtons">
            <button onClick={() => { setUserLowestMIDINote(21); setUserHighestMIDINote(108);}}>Piano</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instruments;