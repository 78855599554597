import { useState } from 'react';
import './App.css';
import DBApp from './db/DBApp';
import Production from './production/Production';
import MelodyApp from './mg/MelodyApp';
import GameOfLife from './gameOfLife/gameOfLife';
import Investing from './investing/investing';
import Comic from './comic/comic';
import EightBall from './8ball/8ball';
import UniversalSimulator from './universalSimulator/universalSimulator';

function App() {
  const [ colorModeClass, setColorModeClass ] = useState('App-dark')
  const [ colorModeButtonLabel, setcolorModeButtonLabel ] = useState('Light Mode');
  const [ menuSelection, setMenuSelection ] = useState('home');
  const [ pageSelection, setPageSelection ] = useState('home');
  const [ subMenu, setSubMenu ] = useState('');
  const viewerWidth = window.innerWidth;
  const viewerHeight = window.innerHeight;

  const toggleColorMode = () => {
    if (colorModeClass === 'App-dark') {
      setcolorModeButtonLabel('Dark Mode');
      setColorModeClass('App-light');
    } else {
      setcolorModeButtonLabel('Light Mode');
      setColorModeClass('App-dark');
    }
  };

  const handleClick = (menu, page) => {
    setMenuSelection(menu);
    setPageSelection(page)
    setSubMenu('');
  }

  const toggleSubMenu = (selection) => {
    if (subMenu === selection) {
      setSubMenu('');
    } else {
      setSubMenu(selection);
    }
  }

  return (
    <div className={colorModeClass}>
      <button id="color-mode-toggle" onClick={toggleColorMode}>{colorModeButtonLabel}</button>
      <header className="App-header" onClick={() => setMenuSelection('home')}>
        <h2><b>Quietman Productions</b></h2>
      </header>
      <div id="menu">
        <div className={ menuSelection === "Music" ? "selected" : "unselected"} onClick={() => toggleSubMenu('Music')}>
          Music
          {(subMenu === 'Music') && <div className="subMenu">
            <ul>
              <li onClick={() => handleClick('Music', 'dangMusicPlayer')}>Recordings</li>
              <li onClick={() => handleClick('Music', 'production')}>Production</li>
              <li onClick={() => handleClick('Music', 'newMelodyGenerator')}>Melody Generator</li>
              <li onClick={() => handleClick('Music', '8Ball')}>Eight Ball</li>
            </ul>
          </div>}
        </div>
        <div className={ menuSelection === "Stories" ? "selected" : "unselected"} onClick={() => toggleSubMenu('Stories')}>
          Stories
          {(subMenu === 'Stories') && <div className="subMenu">
            <ul>
              <li onClick={() => handleClick('Stories', 'storyBoard')}>Story Board</li>
              <li onClick={() => handleClick('Stories', 'universalSimulator')}>Universal Simulator</li>
              <li onClick={() => handleClick('Stories', 'investing')}>Investment Strategy</li>
            </ul>
          </div>}
        </div>
        <div className={ menuSelection === "Games" ? "selected" : "unselected"} onClick={() => toggleSubMenu('Games')}>
          Games
          {(subMenu === 'Games') && <div className="subMenu">
            <ul>
              <li onClick={() => handleClick('Games', 'bellBox')}>Bell Box Game</li>
              <li onClick={() => handleClick('Games', 'gameOfLife')}>Game of Life</li>
            </ul>
          </div>}
        </div>
      </div>
      {(pageSelection === 'home') && <div id="home"><h1>A place for some of Paul's stuff.</h1></div>}
      {(pageSelection === 'dangMusicPlayer') && <DBApp />}
      {(pageSelection === 'production') && <Production />}
      {(pageSelection === 'newMelodyGenerator') && <MelodyApp {...{colorModeClass}} />}
      {(pageSelection === '8Ball') && <EightBall />}
      {(pageSelection === 'storyBoard') && <Comic />}
      {(pageSelection === 'universalSimulator') && <UniversalSimulator />}
      {(pageSelection === 'investing') && <Investing />}
      {(pageSelection === 'bellBox') && <iframe id="bellBoxGame" src=".\games\bell_box\bell_box.html" title="Bell Box Game"></iframe>}
      {(pageSelection === 'gameOfLife') && <GameOfLife {...{viewerWidth, viewerHeight}} />}
    </div>
  );
}

export default App;
