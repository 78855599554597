import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import './gameOfLife.css';
import initialization from './gameOfLife.json';

const GameOfLife = ({viewerWidth, viewerHeight}) => {
    // sets the size of the grid
    const gridSize = useMemo(() => {
        if (viewerHeight - 250 < viewerWidth - 80) {
            return Math.floor((viewerHeight - 250)/5);
        } else {
            return Math.floor((viewerWidth - 80)/5);
        }
    }, [viewerWidth, viewerHeight]);
    const cols = gridSize;
    const rows = gridSize;
    // selected starting point for popular models
    const [ configurationSelection, setConfigurationSelection ] = useState('The R-pentomino');
    const startChoices = Object.keys(initialization);
    // grid is an array that contains the list of pixels that are turned on.
    const  [ grid, setGrid ] = useState();
    // store whether the simulation is running or not.
    const [ running, setRunning ] = useState();
    const startRef = useRef(running);
    startRef.current = running;
    const [ waitTimer, setWaitTimer ] = useState(500);
    const [ generation, setGeneration ] = useState(0);
    const [ maxPopulaton, setMaxPopulation ] = useState(0);

    const positions = useMemo(() => [
        [-1, -1],
        [0, -1],
        [1, -1],
        [-1, 0],
        [1, 0],
        [-1, 1],
        [0, 1],
        [1, 1]
    ], []);

    const blankGrid = () => {
        const tempGrid = [];
        for (let i = 0; i < rows; i++){
            const row = [];
            for (let j = 0; j < cols; j++) {
                row.push(0)
            }
            tempGrid.push(row);
        }
        return tempGrid
    }

    const randomGrid = useCallback(() => {
        const tempGrid = [];
        for (let i = 0; i < rows; i++){
            const row = [];
            for (let j = 0; j < cols; j++) {
                row.push(Math.floor(Math.random() * 2))
            }
            tempGrid.push(row);
        }
        return tempGrid
    }, [cols, rows]);

    const userSelectedGrid = useCallback(() => {
        const gridCenter = [ Math.floor(rows / 2), Math.floor(cols / 2) ];
        const tempGrid = [];
        let maxX = 0, maxY = 0;
        for (let i = 0; i < rows; i++){
            const row = [];
            for (let j = 0; j < cols; j++) {
                row.push(0)
            }
            tempGrid.push(row);
        }
        initialization[configurationSelection].forEach((pixelCoordinates) => {
            if (pixelCoordinates.X > maxX) {
                maxX = pixelCoordinates.X;
            };
            if (pixelCoordinates.Y > maxY) {
                maxY = pixelCoordinates.Y;
            };
        });
        const gridStart = [ gridCenter[0] - Math.floor(maxX / 2), gridCenter[1] - Math.floor(maxY / 2) ];
        initialization[configurationSelection].forEach((pixelCoordinates) => {
            tempGrid[gridStart[0] + pixelCoordinates.X][gridStart[1] + pixelCoordinates.Y] = 1;
        })
        return tempGrid
    }, [configurationSelection, cols, rows]);

    useEffect(() => {
        setGrid(randomGrid())
    }, [randomGrid]);

    useEffect(() => {
        setGrid(userSelectedGrid());
        setMaxPopulation(0);
    }, [userSelectedGrid]);

    const changePixel = useCallback((e, i, j) => {
        const tempGrid = grid;
        if (tempGrid[i][j]) {
            tempGrid[i][j] = 0;
        } else {
            tempGrid[i][j] = 1;
        }
        if (e.target.classList[1] === "on") {
            e.target.classList.remove("on");
            e.target.classList.add("off");
        } else {
            e.target.classList.remove("off");
            e.target.classList.add("on");
        }
        setGrid(tempGrid);
    }, [grid]);
    
    useEffect(() => {
        if (running) {
            setTimeout(() => {
                setGrid((g) => {
                    const next = g.map((row, i) => {
                        return row.map((cell, j) => {
                            let sum = 0
                            positions.forEach((position) => {
                                const x = i + position[0];
                                const y = j + position[1];
                                if (x >= 0 && x < rows && y >= 0 && y < cols) {
                                    sum += g[x][y];
                                }
                            });
                            if (sum < 2 || sum > 3) {
                                return 0;
                            }
                            if (sum === 3) {
                                return 1;
                            }
                            return g[i][j]
                        })
                    })
                    return next
                });
                setGeneration((c) => ++c);                
            }, waitTimer);
        }

    }, [ running, generation, waitTimer, positions, cols, rows ]);

    //  Loads a new starting configuration for the simulation
    const ReinitializeGrid = (selection) => {
        setConfigurationSelection(selection.target.value);
    };

    const population = useMemo(() => {
        if (grid) {
            return grid.reduce((acc, row) => {
                return acc + row.reduce((rowAcc, value) => rowAcc + value, 0);
            }, 0);
        }
    },[grid]);

    if (population > maxPopulaton) {
        setMaxPopulation(population)
    };

    return(
        <div className="lifeViewer">
            <h1>Conway's Game Of Life</h1>
            <div className="grid" style={{ width: cols * 5 }}>
                {grid && 
                    grid.map((rows, i) => 
                        rows.map((col,k) => (
                            <div className={grid[i][k] ? "box on" : "box off"} 
                                key={i + '_' + k}
                                onClick={(e) => {changePixel(e,i,k)}}
                            />
                        ))
                    )
                }
            </div>
            <div className="lifeButtons">
                <button onClick={() => setWaitTimer((time) => time + 100)}>Slower</button>
                <button onClick={() => setRunning(!running)}>{running ? "Stop" : "Start"}</button>
                <button onClick={() => setWaitTimer(
                        (time) => {
                            if (time > 0) {
                                return time - 100
                            } else {
                                return 0
                            }
                        }
                    )}
                >
                    Faster
                </button>
                <button 
                    onClick={() => {setMaxPopulation(0); setGrid(randomGrid); setGeneration(0)}}
                >
                    Random
                </button>
                <button 
                    onClick={() => {setMaxPopulation(0); setGrid(blankGrid); setGeneration(0)}}
                >
                    Blank
                </button>
                <a href="https://en.wikipedia.org/wiki/Conway%27s_Game_of_Life" target='_blank' rel="noreferrer" alt='Wikipedia Page'><button>What?</button></a>
            </div>
            <p>Generation:  {generation}, Interval:  every {waitTimer/1000} second, Population: {population}, Max:  {maxPopulaton}</p>
            <div className="selector">
                <select name="start" id="start" value={configurationSelection} onChange={(item) => {ReinitializeGrid(item); setGeneration(0)}}>
                    {startChoices.map((choice) => <option key={choice} value={choice}>{choice}</option>)}
                </select>
            </div>
        </div>
    )
}

export default GameOfLife