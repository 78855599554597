import { useState } from "react";
import "./comic.css";

const Comic = () => {
    const [ zoom, setZoom ] = useState(false);
    const [ zoomImage, setZoomImage ] = useState("");
    const [ pageSelection, setPageSelection ] = useState(0);
    const fullComic = [
        { "Page 1" : [ "panel1.png", "panel2.png", "panel3.png", "panel4.png", "panel5.png", "panel6.png"]},
        { "Page 2" : [ "panel7.png", "panel8.png", "panel9.png", "panel10.png", "panel11.png", "panel12.png"]},
        { "Page 3" : [ "panel13.png", "panel14.png"]}
    ];

    const panelZoom = (selection) => {
        console.log(selection)
        setZoomImage(selection);
        setZoom(true);
    };

    const closeZoom = () => {
        setZoom(false);
    };

    return (
        <div className="mainDiv">
            {pageSelection !== 0 && <button onClick={() => setPageSelection(pageSelection - 1)}>&larr;</button>}
            <div className="comicLayout">
                {fullComic[pageSelection][Object.keys(fullComic[pageSelection])].map((file) => 
                        <div key={file} className="comicPanel" onClick={() => panelZoom("./comic/" + Object.keys(fullComic[pageSelection]) + "/" + file)}>
                            <img width="512px" height="386px" alt="Click to enlarge" src={"./comic/" + Object.keys(fullComic[pageSelection]) + "/" + file} />
                        </div>
                    )
                }
            </div>
            {pageSelection !== fullComic.length - 1 && <button onClick={() => setPageSelection(pageSelection + 1)}>&rarr;</button>}
            
            {zoom && <div class="popup" id="popup">
                <div class="popup-content">
                    <span className="close-btn" id="close-btn" onClick={closeZoom}>&times;</span>
                    <img src={zoomImage} alt="Full size" />
                </div>
            </div>}
        </div>
    )
}

export default Comic