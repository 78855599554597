import { useState, useMemo } from 'react';
import './DBApp.css';
import artistOrder from "./artists.json";
import catalogue from './CompleteList.json';
import AudioPlayer from './player';

function DBApp() {
  const [cabinetState, setCabinetState] = useState(false);

  //                 Collect The Titles                  //
  const artistAlbumKeys = Array.from(new Set(catalogue.map((item) => (item["artist"] + '-' + item["album"])).sort()));
  const [albumSelection, setAlbumSelection] = useState(artistAlbumKeys[14]);

  //   Catalogue All The Albums and sort them   //
  const artistBins = useMemo(() => {
    let artistAlbumArray = {};
    catalogue.forEach((value) => {
      let currentArtist = value.artist;
      let currentAlbum = value.album;
      let currentAlbumCover = value.artistFolder + '/' + value.albumFolder + '/' + value.cover;
      let currentArtistAlbumKey = (value.artist + '-' + value.album);
      if (!artistAlbumArray[currentArtist]) {
        artistAlbumArray[currentArtist] = [];
      } else if (!artistAlbumArray[currentArtist][currentArtistAlbumKey]) {
        artistAlbumArray[currentArtist][currentArtistAlbumKey] = [currentAlbum, currentAlbumCover];
      };
    });

    return artistAlbumArray;

  },[]);

  //   Catalogue All The Tracks and sort them   //
  const artistAlbumTracks = useMemo(() => {
    let artistTrackArray = {};
    catalogue.forEach((value) => {
      let currentArtistAlbumKey = (value.artist + '-' + value.album);
      let currentTrackDetails = { 
        "track":value.track, 
        "song":value.song, 
        "artist":value.artist, 
        "album":value.album, 
        "location": (value.artistFolder + '/' + value.albumFolder), 
        "file":value.file, 
        "cover":value.cover 
      };
      if (!artistTrackArray[currentArtistAlbumKey]) {
        artistTrackArray[currentArtistAlbumKey] = [];
      }
      artistTrackArray[currentArtistAlbumKey].push(currentTrackDetails);

    });

    return artistTrackArray;

  }, []);
  
  const albumSelected = (selection) => {
    setAlbumSelection(selection); 
    if (selection) {
        setCabinetState(false);
    }
  };

  const cabinetChange = () => {
    if (cabinetState) {
      setCabinetState(false);
    } else {
      setCabinetState(true)
    }
  };

  const playerSongList = useMemo(() => artistAlbumTracks[albumSelection], [albumSelection, artistAlbumTracks]);
  const reversePlayerSongList = useMemo(() => playerSongList.toReversed(), [playerSongList]);
  const [songSelection, setSongSelection] = useState(0);
  const [ flipOrder, setFlipOrder ] = useState(false);
  const currentSongList = useMemo(() => {
    if (flipOrder) {
        return reversePlayerSongList;
    } else {
        return playerSongList;
    };
  }, [flipOrder, playerSongList, reversePlayerSongList]);
  

  const playThis = (songNumber) => {
      setSongSelection(songNumber)
  };

  const flipTheList = () => {
      setFlipOrder(!flipOrder);
  }

  return (
    <div className="system">
      <div className="album-selector">
        <div className="cabinet-controller">
            <button id="cabinet-latch" onClick={cabinetChange}>Album Selector</button>
        </div>
        {cabinetState && 
          <div id="album-cabinet">
          {artistOrder.map((artist) => (
              <div  className="artist" key={artist.trim()}>
                  <h1>{artist}</h1>
                  <div className="artist-albums">
                      {Object.keys(artistBins[artist]).map((album) => 
                              <div key={album.trim()} className="album-display">
                                  <img className="thumbnail" src={`./music/${artistBins[artist][album][1]}`} alt="Album cover" onClick={() => albumSelected(album)}/>
                                  <p>
                                      {artistBins[artist][album][0]}
                                  </p>
                              </div>
                          )
                      }
                  </div>
              </div>
          ))}
      </div>}
      </div>
      <div className="media-player">
        <AudioPlayer {...{ artistAlbumKeys, albumSelection, setAlbumSelection, songSelection, setSongSelection, currentSongList }}/>
      </div>      
      <div className="songListDisplay">
          <div className="playlist">
              <h2>{albumSelection.replace("-", " - ")}</h2>
              <button id="reverse" onClick={() => flipTheList()}>Reverse Order</button>
              <ul id="song-list">
                  {currentSongList.map((track, index) => <li key={track.song} onClick={() => playThis(index)}>{track.track} - {track.song}</li>)}
              </ul>
          </div>
        </div>
    </div>
  );
}

export default DBApp;
