import { useState } from "react"
import "./8ball.css"
import advice from "./8ball.json"

const EightBall = () => {
    const choices = advice.length - 1;
    const [ choice, setChoice] = useState(0);

    return <div className="eightBallMain">
        <div className="eightBall">
            <div className="eightBallWindow">                
                <div className="downTriangle"></div>
                <p className="recommendation"><small>{advice[choice]}</small></p>
            </div>
        </div>
        <button className="shakerButton" onClick={() => setChoice( Math.floor(Math.random() * choices) + 1 )}>Shake the 8-ball</button>
    </div>
}

export default EightBall